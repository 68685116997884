import agent from '../../api/agent';
// import router from '../../router/index';

export default {
    fetchOrder: async ({commit}, id) => {
        try {
            const order = await agent.order.details(id);
            commit('SET_ORDER', order);
        } catch (error) {
            console.error(error);
            // console.log(error);
        }
    },

    setOrderId: ({commit}, id) => {
        commit('SET_ORDER_ID', id);
    },
    
    setOrder: ({commit}, order) => {
        commit('SET_ORDER', order);
    },

    cancelOrder: async ({commit}, id) => {
        try {
            const response = await agent.order.cancel({orderId: id});
            if (response) {
                commit('REMOVE_ORDER');
            }
        } catch (error) {
            console.log(error.response);
        }
    },
}