<template>
    <div class="backdrop" @click="closeModal()">
        <section class="popup flex flex-column justify-center align-center">
            <h2 class="title">{{title}}</h2>
            <p class="info">{{text}}</p>
            <button @click="closeModal()" class="btn btn-primary alternative">Ok</button>
        </section>
    </div>
</template>

<script>
export default {
    name: 'WarningMessageModal',
    props: ['title', 'text'],
    methods: {
        closeModal() {
            this.$store.commit('CLEAR_ERRORS');
            this.$emit('closeModal', false);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.backdrop {
    background-color: rgba($black, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}
.popup {
    position: absolute;
    top: 30rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    border-radius: 0.8rem;
    padding: 5rem 10rem;
    max-width: 80rem;
    box-shadow: 0 0 33px 3px rgba(0, 0, 0, 0.22);
}

.title {
    font-size: 3rem;
    margin-bottom: 2rem;
}

.info {
    font-size: 1.8rem;
    margin-bottom: 5rem;
}
</style>