import agent from '../../api/agent';

export default {
    fetchSettings: async ({commit}) => {
        try {
            commit('SET_SETTINGS', await agent.settings.all())
        } catch (error) {
            console.error(error);
            // console.log(error);
        }
    }
}