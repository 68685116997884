import agent from '../../api/agent';

export default {
    fetchCookieContent: async ({commit}) => {
        // commit('SET_IS_LOADING', true);
        try {
            commit('SET_COOKIE_CONTENT', await agent.pages.cookie());
            // commit('SET_IS_LOADING', false);
        } catch (error) {
            console.error(error);
            // console.log(error.response);
            // commit('SET_IS_LOADING', false);
        }
    },

    fetchPrivacyContent: async ({commit}) => {
        // commit('SET_IS_LOADING', true);
        try {
            commit('SET_PRIVACY_CONTENT', await agent.pages.privacy());
            // commit('SET_IS_LOADING', false);
        } catch (error) {
            console.error(error);
            // console.log(error.response);
            // commit('SET_IS_LOADING', false);
        }
    },

    fetchTermsContent: async ({commit}) => {
        // commit('SET_IS_LOADING', true);
        try {
            commit('SET_TERMS_CONTENT', await agent.pages.terms());
            // commit('SET_IS_LOADING', false);
        } catch (error) {
            console.error(error);
            // console.log(error.response);
            // commit('SET_IS_LOADING', false);
        }
    },
}