<template>
    <main class="home">
        <Hero />
        <LoadingSpinner v-if="isLoading" />
        <section v-else class="product-overview">
            <div class="grid grid-pad">
                <div class="flex">                    
                    <ProductOverviewCard v-for="product in products" :key="product.id" :product="product" />
                </div>
                <div class="col-1-1 flex justify-center">
                    <router-link class="btn btn-primary" to="/product-catalog">{{viewAllProducts}}</router-link>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import Hero from '@/components/Hero'
import ProductOverviewCard from '@/components/ProductOverviewCard'
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { mapGetters } from 'vuex';

export default {
    name: 'Home',
    components: { 
        Hero, 
        ProductOverviewCard, 
        LoadingSpinner 
    },
    
    computed: {
        ...mapGetters(['isLoading', 'viewAllProducts', 'metaTitle']),
        ...mapGetters({products: 'getProducts'})
    },

    created() {
        document.title = 'Home' + this.metaTitle;
    },
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.product-overview {
    // padding: 10rem 0;
    padding: 30rem 0 10rem 0;
}

.flex {
    margin-bottom: 4rem;
}
</style>