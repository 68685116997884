import agent from '../../api/agent';

export default {
    fetchTexts: async ({commit}) => {
        try {
            const textsResult = await agent.texts.all();
            commit('SET_HOME_TEXT', textsResult.homeText);
            commit('SET_TEXTS', textsResult.texts);
        } catch (error) {
            // console.log(error)
            console.error(error);
        }
    }
}